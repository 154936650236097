.table-container {
  max-width: calc(100vw - 3rem);
  overflow-x: auto;
}

table {
  border-spacing: 0;
}

th, 
td {
  padding: 1em;
  text-align: left;
  vertical-align: top;
}

.service-list__status {
  min-width: 25em;
}

tbody tr:nth-child(2n+1) {
  background: hsla(360, 0%, 0%, 0.05);
}

.service-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
}

.error-message {
  background-color: red;
  color: white;
}

@keyframes fade-in {
  0% {
    background: yellow;
  }
}

.service-item--fade-in {
  animation-name: fade-in;
  animation-duration: 2s;
}
