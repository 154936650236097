
body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

h1 {
  font-size: 2em;
}

.header {
  background: hsla(360, 0%, 0%, 0.05);
  padding: 1.5em;
}

.service-list,
.service-form {
  width: max-content;
  margin: 0 auto;
  padding: 1.5rem;
}

/* https://medium.com/@DavidKPiano/css-animations-with-finite-state-machines-7d596bb2914a */
[data-state="list"] .service-form,
[data-state="form"] .service-list {
  opacity: 0;
  position: absolute;
  left: -9999px;
}
