
.service-status--pending {
  font-style: italic;
}

.service-status--error {
  color: red;
}

.service-status--success {
  color: green;
}

.service-status button {
  margin-left: 1em;
}
