
.service-form__list,
.service-form__list-item {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.service-form__list-item {
  margin-top: 1.5em;
}

.service-form__submit {
  margin-top: 3em;
}

.field__label {
  display: block;
  margin-bottom: 0.25em;
}

.note {
  margin-left: 1.5em;
  vertical-align: middle;
  font-style: italic;
}

input,
select {
  font-size: inherit;
  font-family: inherit;
  min-width: 15em;
  box-sizing: border-box;
}

input[type="number"] {
  width: 5em;
  min-width: 5em;
}

.service-form__submit button {
  font-size: 1.5em;
}
